<!-- 日期搜索框 -->
<template>
  <div id="myDate">
    <div class="m-datepicker">
      <!-- 年 -->
      <div class="b-field-input" @mouseenter="enter()" @mouseleave="leave()">
        <div class="value default-value">
          <!-- {{ year == "" ? "年" : year + "年" }} -->
          <span v-if="year == ''">年</span>
          <span v-else class="val-e">{{ year + "年" }}</span>
        </div>
        <!--  -->
        <div v-show="yearBtn" class="picker yearPicker">
          <div class="year" v-for="(item, index) in years" :key="index">
            <span style="" class="name">{{ item[1].value }}:</span>
            <div v-for="(items, indexs) in item" :key="indexs">
              <div
                v-show="newyear - itemss.value >= 18"
                class="option"
                @click="al(itemss.value)"
                v-for="(itemss, indexss) in items"
                :key="indexss"
              >
                <div v-if="newyear - itemss.value >= 18">
                  {{ itemss.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 月 -->
      <div class="b-field-input" @mouseenter="Menter()" @mouseleave="Mleave()">
        <div class="value default-value">
          <!-- {{ month == "" ? "月" : month + "月" }} -->
           <span v-if="month == ''">月</span>
          <span v-else class="val-e">{{ month + "月" }}</span>
        </div>
        <!-- 日期 -->
        <div class="months picker monthsPicker" v-show="monthBtn">
          <div class="month-tips" v-show="a">请先选年份</div>
          <div class="month-tips" v-show="!a">月</div>
          <div class="months-num" v-show="!a">
            <div
              v-for="item in months"
              :key="item.value"
              class="option"
              @click="ml(item.value)"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <!-- 日 -->
      <div class="b-field-input" @mouseenter="Denter()" @mouseleave="Dleave()">
        <div class="value default-value">
          <!-- {{ day == "" ? "日" : day + "日" }} -->
          <span v-if="day == ''">日</span>
          <span v-else class="val-e">{{ day + "日" }}</span>
        </div>
        <!-- 日期 -->
        <div class="days picker daysPicker" v-show="dayBtn">
          <div class="day-tips" v-show="b">请先选年份,月份</div>
          <div class="day-tips" v-show="!b">日</div>
          <div class="days-num" v-show="!b">
            <div
              v-for="item in days"
              :key="item.value"
              class="option"
              @click="dl(item.value)"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  props: ["data1"],
  data() {
    return {
      yearsModel: null,
      years: [],
      monthsModel: null,
      months: [],
      daysModel: null,
      days: [],
      yearBtn: false,
      monthBtn: false,
      dayBtn: false,
      year: "",
      month: "",
      day: "",
      newyear: "",
      a: true,
      m: false,
      b: true,
      csrq: "",
    };
  },

  created() {
    this.init();
    let myDate = new Date();
    this.newyear = myDate.getFullYear();
  },
  methods: {
    //年
    al(index) {
      // 父数据
      // console.log("asd", this.data1);
      this.year = index;
      // console.log( "年",this.year);
      if (this.year) {
        this.monthBtn = true;
        this.yearBtn = false;
      }
    },
    ZeroFilling(num) {
      if (num < 10) {
        return `0${num}`;
      } else {
        return `${num}`;
      }
    },
    //月
    ml(c) {
      this.month = c;
      let a = this.year;
      if (c) {
        //调用天数
        this.initSelectDay(a, c);
      }
      if (c == 2) {
        if (
          (this.year % 4 == 0 && this.year % 100 != 0) ||
          this.year % 400 == 0
        ) {
          // console.log("闰年");
          if (this.day >= 30) {
            this.day = "";
          }
        } else {
          // console.log("平年");
          if (this.day >= 30 || this.day == 29) {
            this.day = "";
          }
        }
      }
      if (this.month) {
        this.dayBtn = true;
        this.monthBtn = false;
      }
    },
    dl(d) {
      this.day = d;
      if (this.day) {
        this.dayBtn = false;
      }
      // console.log( "日",this.day);
      // 向父组件传值
      let a = this.ZeroFilling(this.month);
      let b = this.ZeroFilling(this.day);
      this.$emit("Ymdata", `${this.year}-${a}-${b}`);
    },
    //显示日历 //年  事件一出一入
    enter() {
      this.yearBtn = true;
    },
    leave() {
      this.yearBtn = false;
      if (this.year) {
        this.a = false;
      }
    },

    //月
    Menter() {
      this.monthBtn = true;
      if (!this.year) {
        this.a = true;
      } else {
        this.monthBtn = true;
      }
    },
    Mleave() {
      this.monthBtn = false;
      if (this.year) {
        this.b = false;
      }
    },

    //日
    Denter() {
      this.dayBtn = true;
      if (!this.year) {
        this.b = true;
      } else {
        this.dayBtn = true;
      }
    },
    Dleave() {
      this.dayBtn = false;
      if (!this.year) {
        this.b = false;
      }
    },
    //初始化数据
    init() {
      var myDate = new Date();
      let year = myDate.getFullYear();
      // console.log(year);
      var month = myDate.getMonth() + 1; //获取当前月
      for (var i = 1; i <= 30; i++) {
        this.days.push({ value: i, label: i + "日" });
      }
      this.initSelectYear(2009);
      // this.initSelectYear(1930);
      this.initSelectMonth();
      this.yearsModel = year;
      this.monthsModel = month;
    },
    //年份添加
    initSelectYear(year) {
      this.years = [];
      let b = [];
      let a = [];
      //顺序
      for (let i = 0; i < 92; i++) {
        a.push({ value: year - i, label: year - i + "年" });
      }
      b.push([a.slice(0, 10).reverse(), { value: "00后" }]);//.reverse()数组倒叙
      b.push([a.slice(10, 20).reverse(), { value: "90后" }]);
      b.push([a.slice(20, 30).reverse(), { value: "80后" }]);
      b.push([a.slice(30, 40).reverse(), { value: "70后" }]);
      b.push([a.slice(40, 50).reverse(), { value: "60后" }]);
      b.push([a.slice(50, 60).reverse(), { value: "50后" }]);
      b.push([a.slice(60, 70).reverse(), { value: "40后" }]);
      b.push([a.slice(70, 80).reverse(), { value: "30后" }]);
      this.years = b;
    },
    //月份添加
    initSelectMonth() {
      this.months = [];
      for (let i = 1; i <= 12; i++) {
        this.months.push({ value: i, label: i + "月" });
      }
    },
    //日添加
    initSelectDay(year, month) {
      var maxDay = this.getMaxDay(year, month);
      this.days = [];
      for (var i = 1; i <= maxDay; i++) {
        this.days.push({ value: i, label: i + "日" });
      }
    },

    getMaxDay(y, m) {
      if (m == 1) {
        return 31;
      } else if (m == 2) {
        if ((y % 4 == 0 && y % 100 != 0) || y % 400 == 0) {
          // console.log("闰年");
          return 29;
        } else {
          // console.log("1111");
          return 28;
        }
      } else if (m == 3) {
        return 31;
      } else if (m == 4) {
        return 30;
      } else if (m == 5) {
        return 31;
      } else if (m == 6) {
        return 30;
      } else if (m == 7) {
        return 31;
      } else if (m == 8) {
        return 31;
      } else if (m == 9) {
        return 30;
      } else if (m == 10) {
        return 31;
      } else if (m == 11) {
        return 30;
      } else if (m == 12) {
        return 31;
      }
    },
  },
};
</script>
<style  lang="less" scoped>
.m-datepicker {
  position: relative;
  display: flex;
}
.m-datepicker .value {
  display: inline-block;
  width: 90px;
  height: 40px;
  border-radius: 4px;
  padding: 0 8px;
  background-color: #f4f4f4;
  // color: #333333;
  text-align: right;
  line-height: 40px;
  cursor: pointer;
  font-size: 14px;
}
.val-e{
  color: #000;
}
// .m-datepicker .default-value {
//   color: #ababab;
// }
.m-datepicker .select-value {
  color: #333333;
}
.m-datepicker .title {
  padding-bottom: 10px;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
}
.m-datepicker .picker {
  font-size: 16px;
  min-height: 222px;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -4px;
  padding: 15px;
  z-index: 20;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.3);
}
// .m-datepicker .picker.yearPicker {
//   // width: 482px;
// }
.m-datepicker .picker .year {
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.m-datepicker .picker:hover {
  display: block;
}
.m-datepicker .picker .name {
  font-weight: bold;
  margin-right:5px;
}
.m-datepicker .picker .option {
  display: inline-block;
  padding: 4px;
  color: #333333;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.m-datepicker .picker .option:hover {
  background-color: #d9d9d9;
}
.m-datepicker .picker.monthsPicker .option {
  width: 8.33333%;
}
.m-datepicker .picker.daysPicker .option {
  width: 25px;
  padding: 10px;
}
.days-num {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.m-datepicker .b-field-input {
  // width: 100px;
  // height: 39px;
  margin-left: 10px;
}
.m-datepicker .b-field-input .months .months-num {
  display: flex;
}
.m-datepicker .picker.monthsPicker {
  width: 338px;
}
.m-datepicker .picker.daysPicker {
  width: 338px;
}
.m-datepicker .b-field-input .months .months-num div {
  margin-top: 10px;
  margin-right: 10px;
}
.m-datepicker .b-field-input:nth-child(1) {
  margin-left: 0;
}
</style>

