import request from "@/api/http.js"

//查询最近脱单集合
export const desinglelist = ((data) => {
    // 
    return request.post('/member-server/member/info/page/desingle', data)
})
// 查看猜你喜欢 /member/info/guess/like
export const guessLike = ((data) => {
    return request.postt('/member-server/member/info/guess/like', data)
})

// 消息,关注,看过/member/info/msg/total
export const msgTotal = ((data) => {
    return request.postt('/member-server/member/info/msg/total', data)
})

// 发消息 /member/message/send
export const sendmessage = ((data) => {
    return request.postt('/member-server/member/message/send', data)
})

//申请服务 /member/service/app/app
export const ApplicationServices = ((data) => {
    return request.postt('/member-server/member/service/app/app', data)
})

//提交会员举报 /member/inform/inform
export const informInfom = ((data) => {
    return request.post('/member-server/member/inform/inform', data)
})

//系统配置  /member/config/list
export const configList = ((data) => {
    return request.postt('/member-server/member/config/list', data)
})

//更新会员配置 /member/config/update
export const configUpdate = ((data) => {
    return request.post('/member-server/member/config/update', data)
})

//配置屏蔽 /member/shield/shield/config
export const configShield = ((data) => {
    return request.postt('/member-server/member/shield/shield/config', data)
})

//添加会员相册 
export const albumAdd = ((data) => {
    return request.postt('/member-server/member/album/add', data)
})
//头像 /member/info/update/approve
export const approveUpdate = ((data) => {
    return request.post('/member-server/member/info/update/approve', data)
})
//删除相册 /member/album/del 
export const albumDel = ((data) => {
    return request.postt('/member-server/member/album/del', data)
})
//相册列表 /member/album/list /member/album/list
export const albumList = ((data) => {
    return request.postt('/member-server/member/album/list', data)
})

//根据参数查询门店信息列表  /member/shop/add

//短信接口 /sys/sms/send
export const sendSms = ((data) => {
    return request.postt('/system-server/sys/sms/send', data)
})
//校验验证码 /sys/sms/valid
export const validSms = ((data) => {
    return request.postt('/system-server/sys/sms/valid', data)
})
//判断手机号是否存在 /member/info/vaild/phone
export const validPhone = ((data) => {
    return request.postt('/member-server/member/info/vaild/phone', data)
})

// 查看会员故事 /member/story/list
export const storyList = ((data) => {
    return request.postt('/member-server/member/story/list', data)
})
//获取临牌
export const subTk = ((data) => {
    return request.postt('/system-server/sys/sub/tk', data)
})


//推荐  /member/info/page/recommend
export const InforRecommend = ((data) => {
    return request.postt('/member-server/member/info/page/recommend', data)
})

//she /member-server/member/info/all/appStatus
export const allAppStatus = ((data) => {
    return request.postt('/member-server/member/info/all/appStatus', data)
})

//极验接口
export const Register = (() => {
    // return requestL.Axiosget(`/register?t=${new Date().getTime()}`)
    return request.Axiosget(`/geetest/register?t=${new Date().getTime()}`)
})

// export const Action = ((data) => {
//     // return requestL.Axiosget(`/register?t=${new Date().getTime()}`)
//     return requestLL.faceid('',data)
// })



