import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css';

//测试  自定义长按一秒触发函数
Vue.directive('longpress', {
  bind: function (el, binding, vNode) {
    console.log(vNode);
    if (typeof binding.value !== 'function') {
      throw 'callback must be a function'
    }
    // 定义变量
    let pressTimer = null
    // 创建计时器（ 2秒后执行函数 ）
    let start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return
      }
      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          handler()
        }, 1000)
      }
    }
    // 取消计时器
    let cancel = (e) => {
      console.log(e);
      if (pressTimer !== null) {
        clearTimeout(pressTimer)
        pressTimer = null
      }
    }
    // 运行函数
    const handler = (e) => {
      binding.value(e)
    }
    // 添加事件监听器
    el.addEventListener('mousedown', start)
    el.addEventListener('touchstart', start)//在移动端要考虑 touchstart，touchend 事件
    // 取消计时器
    el.addEventListener('click', cancel)
    el.addEventListener('mouseout', cancel)
    el.addEventListener('touchend', cancel)//在移动端要考虑 touchstart，touchend 事件
    el.addEventListener('touchcancel', cancel)//在移动端要考虑 touchstart，touchend 事件
  },
  // 当传进来的值更新的时候触发
  componentUpdated(el, { value }) {
    el.$value = value
  },
  // 指令与元素解绑的时候，移除事件绑定
  unbind(el) {
    el.removeEventListener('click', el.handler)
  },
})
//测试--end

//极验引入
import "@/api/common/Jy/gt.js";

//引入组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { message } from '@/api/common/resetMessage.js'
Vue.use(ElementUI);
// Vue.use(ElementUI)
Vue.prototype.$message = message;

//添加头部信息
import Meta from 'vue-meta-info'
Vue.use(Meta)

//判断当前是以打开方式
import isMobile from './assets/js/iSMobile.js'
Vue.prototype.$isMobile = isMobile // 用$与组件内自定义的函数区分，其他符号也可以

//加密 引用md5插件 //将md5绑定到全局
import md5 from 'js-md5'
Vue.prototype.$md5 = md5

//环信聊天
// import $WebIM from './utils/WebIM';
// Vue.prototype.WebIM = $WebIM
import WebIM from './utils/WebIM';
Vue.prototype.$WebIM = WebIM

//时间处理
import moment from 'moment'
Vue.prototype.$moment = moment
moment.locale('zh-cn');
// 在 main.js 中引入
// import CsRuler from 'cs-ruler'
// Vue.use(CsRuler)
// import eventBus from 'vue'

//百度地图
import BaiduMap from 'vue-baidu-map'
// import bmap from "vue-baidu-map/components/map/Map.vue";
Vue.use(BaiduMap, {
  ak: "cmmtZ69mjRmZz2Xp9P5ND2GhjO7bBxvO"
})

Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false

////////////////////////////////////////////////////////////////////
//                          _ooOoo_                               //
//                         o8888888o                              //
//                         88" . "88                              //
//                         (| ^_^ |)                              //
//                         O\  =  /O                              //
//                      ____/`---'\____                           //
//                    .'  \\|     |//  `.                         //
//                   /  \\|||  :  |||//  \                        //
//                  /  _||||| -:- |||||-  \                       //
//                  |   | \\\  -  /// |   |                       //
//                  | \_|  ''\---/''  |   |                       //
//                  \  .-\__  `-`  ___/-. /                       //
//                ___`. .'  /--.--\  `. . ___                     //
//              ."" '<  `.___\_<|>_/___.'  >'"".                  //
//            | | :  `- \`.;`\ _ /`;.`/ - ` : | |                 //
//            \  \ `-.   \_ __\ /__ _/   .-` /  /                 //
//      ========`-.____`-.___\_____/___.-`____.-'========         //
//                           `=---='                              //
//      ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^        //
//            佛祖保佑       永不宕机     永无BUG                   //
////////////////////////////////////////////////////////////////////

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // document.dispatchEvent(new Event('render-event'))
    if (this.$isMobile()) {
      //这里是移动端
      // alert("手机端");
      // window.location.href = 'http://mweb.yezai.love' http://i.yezai.love/
      window.location.href = 'http://i.yezai.love'
    } else {
      //这里是pc端
      // alert("pc端");
      document.dispatchEvent(new Event('render-event'))

      // document.dispatchEvent(new Event('custom-render-trigger')) // 预渲染
    }
  }
  ,
  metaInfo: {
    title: "也在网-征婚、交友、相亲，严肃单身婚恋交友平台",
    // meta: [
    //   { charset: "utf-8" },
    //   {
    //     name: "viewport",
    //     content:
    //       "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
    //   },
    //   {
    //     name: "keywords",
    //     content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！" 
    //   }, {
    //     name: "description",
    //     content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
    //   }
    // ],
  }
}).$mount('#app')


