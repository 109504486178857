import request from "@/api/http.js"

//注册
// export const Addregister = ((data) => {
//     return request.post('/yezai-member/member/info/register', data)
// })

//会员注册
export const Mregister = ((data) => {
    return request.post('/yezai-auth/member/register', data)
})

// 登录
export const loginAuth = ((data) => {
    return request.post('/yezai-auth/member/auth', data)
})

//ip属地
export const updateTerritory = (() => {
    return request.postt('/member-server/member/info/update/territory')
})
//--------------------------------------------------------------------------------
//当前用户信息
export const infocur = ((data) => {
    return request.get('/member-server/member/info/info/cur', data)
})
//当前用户信息资料百分比
export const infoPerfection = ((data) => {
    return request.get('/member-server/member/info/info/perfection', data)
})

//当前用户信息
export const query = ((data,to) => {
    return request.get(`/member-server/member/info/info/${data}`,to)
})

//更改用户信息
export const infoUpdate = ((data) => {
    return request.post(`/member-server/member/info/update`, data)
})
//获取当前用户附属信息 /member/sub/info
export const infosub = ((data) => {
    return request.get('/member-server/member/sub/info', data)
})
//更新附属信息 /member/sub/update
export const updatesub = ((data) => {
    return request.post('/member-server/member/sub/update', data)
})
//刷新  
export const infoefresh = ((data) => {
    return request.get('/member-server/member/info/refresh', data)
})
//---------------------------------------择偶---------------------------------------
//获取用户择偶条件
export const infoselection = ((data) => {
    return request.get('/member-server/member/mate/selection/info', data)
})
// 更新用户择偶条件
export const updateselection = ((data) => {
    // return request.post('/member-server/member/mate/selection/update', data)
    return request.post('/member-server/member/mate/selection/update/all', data)
})
//------------------------------------信息------------------------------------------
//根据参数查询会员基础信息列表
// export const infolist = ((data) => {
//     return request.post('/member-server/member/info/list', data)
// })
export const infolist = ((data) => {
    return request.post('/member-server/member/info/page', data)
})
//添加会员基础信息(补全信息)
export const Addinfo = ((data) => {
    return request.post('/member-server/member/info/add', data)
})
//-------------------------------------关注---------------------------------------------
//会员关注/取消 (用户关注)
export const followin = ((data) => {
    return request.postt('/member-server/member/follow/follow', data)
})
// 当前用户是否被关注 
export const infofollow = ((data) => {
    return request.postt('/member-server/member/follow/info', data)
})
//参数查询会员关注,被关注 集合 /member/follow/list
export const follolist = ((data) => {
    return request.postt('/member-server/member/follow/list', data)
})
// 参数查询会员被关注 /member/view/following/page
export const followingPage = ((data) => {
    return request.postt('/im-server/member/view/following/page', data)
})
// 参数查询会员被关注 /member/follow/page
export const followPage = ((data) => {
    return request.postt('/member-server/member/follow/page', data)
})
//-------------------------------屏蔽------------------------------------------------
// 屏蔽/取消屏蔽 /member/shield/shield
export const shield = ((data) => {
    return request.postt('/member-server/member/shield/shield', data)
})
// 获取当前 屏蔽状态 /member/shield/info
export const shieldInfo = ((data) => {
    return request.postt('/member-server/member/shield/info', data)
})
//------------------------------浏览--------------------------------------------
//用户浏览记录
export const browsing = ((data) => {
    return request.postt('/im-server/member/browsing/record/add', data)
})
// 浏览记录列表
export const browsinglist = ((data) => {
    return request.postt('/im-server/member/view/browsing/list', data)
})

// 浏览记录分页(谁看过我)
export const browsingPage = ((data) => {
    return request.postt('/im-server/member/view/browsing/page', data)
})
// 删除浏览记录 /member/view/del
export const viewDel = ((data) => {
    return request.postt('/im-server/member/view/del', data)
})
//-----------------------------消息接口-------------------------------------------
//获取当前用户消息分页 /member/message/page   /member/message/view/message/page
export const messagePage = ((data) => {
    return request.postt('/member-server/member/message/view/message/page', data)
})
export const messageDel = ((data) => {
    return request.postt('/member-server/member/message/view/del', data)
})
//发送消息
export const send = ((data) => {
    return request.postt('/member-server/member/message/send', data)
})
//消息 /member/message/update/status
export const messageStatusUpdate  = ((data) => {
    return request.postt('/member-server/member/message/update/status', data)
})
//member-server
//system-server
//yezai-auth