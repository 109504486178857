<template>
  <div id="app" ref="VueApp">
    <!-- style="display: none" -->
    <router-view />
  </div>
</template>
<script>
export default {
  created() {},
  mounted() {
    // this.$refs.VueApp.style.display = "block";
  },
};
</script>


<style lang="less">
/**
element-UI  设置弹框
*/
.popper_select {
  z-index: 9999 !important;
}
html,
body,
#app {
  height: 100%;
}
</style>
