// 引入包
import axios from "axios";
import Qs from "qs";
import local from "@/api/common/local.js"
// import { Message } from "element-ui"

import { message } from '@/api/common/resetMessage.js'

import router from '@/router'
const instance = axios.create({});

// instance.defaults.baseURL = 'http://192.168.100.113:8081';

if (process.env.NODE_ENV == "development") {
    // instance.defaults.baseURL = '/api2'
    // instance.defaults.baseURL = 'http://192.168.100.119:8081';
    // instance.defaults.baseURL = 'http://192.168.100.117:8081';
    // instance.defaults.baseURL = 'http://192.168.100.117:8081';
    instance.defaults.baseURL = 'https://api.yezai.love';
} else if (process.env.NODE_ENV == "production") {
    instance.defaults.baseURL = 'https://api.yezai.love';
}

// instance.defaults.baseURL = '/api2'
// console.log('shuju',process.env.VUE_APP_BASE_API  );
// instance.defaults.baseURL = process.env.VUE_APP_BASE_API  
// instance.defaults.baseURL = 'http://192.168.100.112:8080/api';
// axios.defaults.baseURL = '/api'
//指定请求超时的毫秒数
instance.defaults.timeout = 5000;
// instance.defaults.timeout = 50000;

// 请求拦截器
instance.interceptors.request.use(config => {
    let token = local.get("access_token");
    if (token) {
        config.headers.Authorization = local.get("access_token")
    }
    return config;
})

// 响应拦截器
// axios.interceptors.response.use(function () {
//     // 对响应数据做点什么
//     // let code = res.data.code, msg = res.data.msg;
//     // if (code !== undefined && code !== "00" && code !== "11") {
//     //     if (code === 0) {
//     //         // Message.success(msg);
//     //         this.$message({
//     //             message: msg,
//     //             type: "success",
//     //             offset: 100,
//     //         });
//     //     } else {
//     //         // Message.error(msg)
//     //         this.$message({
//     //             message: msg,
//     //             type: "success",
//     //             offset: 100,
//     //         });
//     //     }
//     // }
//     // return res;
// }, function (err) {
//     // 对响应错误做点什么
//     // return Promise.reject(error);
//     if (err.response.status === 401) {
//         // Message.error("")

//         this.$message({
//             message:'身份过期,请重新登录',
//             type: "success",
//             offset: 100,
//         });
//         this.$router.push("/login");
//     }
//     // return err;
// });
// axios.
//发情求,做处理
instance.interceptors.response.use(function (response) { // ①10010 token过期（30天） ②10011 token无效

    // if (response.data.code === 10010 || response.data.code === 10011) {
    // "没有权限，请联系管理员授权"
    if (response.data.code === 2) {
        // if (response.data.msg === "没有权限，请联系管理员授权") {
        // console.log('数据');
        // localStorage.Remove('access_token') // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）
        // this.$router.replace({
        //     path: '/login' // 到登录页重新获取token

        // })
        // window.location.href = '/login'
        local.clear()
        router.push('/login')

    } 
    // else if (response.data.code === 1) { // 判断token是否存在，如果存在说明需要更新token
    //     local.clear()
    //     router.push('/login')// 覆盖原来的token(默认一天刷新一次)
    // }
    else if (response.data.access_token) { // 判断token是否存在，如果存在说明需要更新token
        Storage.localSet('access_token', response.data.access_token) // 覆盖原来的token(默认一天刷新一次)
    }
    return response

}, function (error) { //error
    // Message.error("网络请求超时,请稍后再试")
    // console.log('ss',Promise.reject(error));
    message.error({
        message: "网络请求超时,请稍后再试",
        offset: 100,
    })


    return Promise.reject(error)

})

// const Axiosget = (url) => {
//     return new Promise((resolve, reject) => {
//         axios.get(url).then((response) => {
//                 resolve(response.data);
//             }).catch((err) => {
//                 reject(err)
//             })
//     })
// }

const get = (url, params) => {
    return new Promise((resolve, reject) => {
        instance.get(url,
            {
                headers: {
                    "access-token": params,
                }
            }).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
    })
}
// /member/follow/info/{followMemberId}
const gett = (url, params) => {
    return new Promise((resolve, reject) => {
        // let token = local.get("access_token");
        instance.get(url,
            {
                headers: {
                    "access-token": `${params.token}`,

                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}
const gettL = (url) => {
    return new Promise((resolve, reject) => {
        // let token = local.get("access_token");
        instance.get(url).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}
const gettLL = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        instance.get(`${url}?${Qs.stringify(data)}`, {
            headers: {
                "access-token": `${token}`,
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

// let token = local.get("access_token");
// axios.interceptors.request.use(function (config) {
// let token = local.get("access_token");  //从缓存中取token
//     if (token) {
//         config.headers.access_token == token;    //将token放到请求头发送给服务器

//     }
// })

const post = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        let tk = local.SessionGet('sub_tk')
        instance.post(url, data, { headers: { 'Content-Type': 'application/json', 'access-token': `${token}`, 'sub-tk': `${tk}` } }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

const postt = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        let tk = local.SessionGet('sub_tk')
        instance.post(url, Qs.stringify(data), { headers: { 'access-token': `${token}`, 'sub-tk': `${tk}` } }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}
const posttL = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        instance.post(url, Qs.stringify(data), { headers: { 'access-token': `${token}`, 'sub-tk': `${data.tk}` } }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

const DELETE = (url) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        instance.delete(url, { headers: { 'access-token': `${token}` } }
        ).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err)
        })
    })
}

//极验
const Axiosget = (url) => {
    return new Promise((resolve, reject) => {
        instance.get(url).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

export default {
    get,
    post,
    postt,
    gett,
    posttL,
    gettL,
    gettLL,
    DELETE,
    Axiosget
}