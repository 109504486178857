export default{
    get(key){
        return JSON.parse(localStorage.getItem(key))
    },
    set(key,val){
        localStorage.setItem(key,JSON.stringify(val))
    },
    remove(key){
        localStorage.removeItem(key)
    },
    clear(){
        localStorage.clear()
    },
    SessionGet(key){
        return JSON.parse(sessionStorage.getItem(key))
    },
    SessionSet(key,val){
        sessionStorage.setItem(key,JSON.stringify(val))
    },
    
}