import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index'
// import index from '@/views/index/indexA.vue'
import log from '@/views/log'
import notfound from '@/views/NotFoundComponent'

// 引入城市组件
// import CityComponent from '@/components/CityComponent';

Vue.use(VueRouter)

const routes = [
  //空白页跳转
  {
    path: '/',
    name: 'log',
    component: log
  },
  //宣传页
  {
    path: '/home',
    name: 'index',
    component: index
  },
  // {
  //   path: '/',
  //   name: 'index',
  //   component: index
  // },
  //登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login'),

  },
  //注册页
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register')
  },
  //忘记密码密码修改
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import('@/views/forgotpassword')
  },
  // 导航页
  {
    path: '/n',
    name: 'n',
    component: () => import('@/components/layout/index.vue'),
    children: [
      {
        path: '/n/myyezai',
        name: 'myyezai',
        component: () => import("@/views/myyezai")
      }, {
        path: '/n/search',
        component: () => import("@/views/search")
      }, {
        path: '/n/yezaiMember',
        component: () => import("@/views/yezaiMember"),
      }
    ]
  },
  // 直营门店
  {
    path: '/directStore',
    name: 'directStore',
    component: () => import("@/components/directStoreLayout/index.vue"),
    children: [
      {
        path: '/directStore/home',
        name: 'directStoreHome',
        component: () => import("@/views/directStore/homePage"),

      },
      {
        path: '/directStore/_service',
        name: 'directStore_service',
        component: () => import("@/views/directStore/service")
      },
      {
        path: '/directStore/_address',
        name: 'directStore_address',
        component: () => import("@/views/directStore/address")
      }
    ]
  },

  // 消息框
  {
    path: '/n/message',
    name: 'message',
    component: () => import("@/views/myyezai/message"),
    children: [{
      path: '/n/message',
      name: 'message',
      component: () => import("@/views/myyezai/message/left/message")
    }, {
      path: '/n/message/_follow',
      name: 'message_follow',
      component: () => import("@/views/myyezai/message/left/follow")
    }, {
      path: '/n/message/_visit',
      name: 'message_visit',
      component: () => import("@/views/myyezai/message/left/visit")
    }]
  },
  //资料
  {
    path: '/n/user/_baseInfo/left',
    component: () => import("@/components/myyezai"),
    children: [
      {
        path: '/n/user/_baseInfo/left',
        // name:'_baseInfoLeft',
        component: () => import("@/components/myyezai/left/baseInfo"),
      }, {
        path: '/n/user/_deta',
        component: () => import("@/components/myyezai/left/deta")
      }, {
        path: '/n/user/_workLife',
        component: () => import("@/components/myyezai/left/workLife")
      }, {
        path: '/n/user/_heartWord',
        component: () => import("@/components/myyezai/left/heartWord")
      }, {
        path: '/n/user/_interest',
        component: () => import("@/components/myyezai/left/interest")
      }, {
        path: '/n/user/_spousePreference',
        component: () => import("@/components/myyezai/left/spousePreference")
      },
      {
        path: '/n/user/_auth',
        component: () => import("@/components/myyezai/left/auth")
      },

      {
        path: '/n/user/_album',
        component: () => import("@/components/myyezai/left/album")
      }, {
        path: '/n/user/_setting/left',
        component: () => import("@/components/myyezai/left/setting")
      }, {
        path: '/n/user/_password',
        component: () => import("@/components/myyezai/left/password")
      },
      {
        path: '/n/user/_avatar',
        component: () => import("@/components/myyezai/left/avatar")
      },
      //avatar
    ]
  },
  //个人资料
  {
    path: '/u',
    component: () => import("@/views/personalData")
  },
  //聊天
  {
    path: '/chat',
    component: () => import("@/views/myyezai/chat")
  },
  //也在币
  {
    path: '/yezaigold',
    name: 'yezaigold',
    component: () => import('@/views/yezaigold')
  },
  //安全中心
  {
    // path: '/n/safe',
    path: '/safe',
    name: 'safe',
    component: () => import('@/views/safe')
  },
  //关于我们
  {
    // path: '/n/aboutUs',
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutyezai')
  },
  //简介
  {
    // path: '/n/introduce',
    path: '/introduce',
    name: 'introduce',
    component: () => import('@/views/introduce')
  },
  {
    path: '/emotionalcounsel',
    name: 'emotionalcounsel',
    component: () => import('@/views/emotionalcounsel')
  },
  {

    path: '/companynews',
    name: 'companynews',
    component: () => import('@/views/companynews')
  }, {
    path: '/articleDetails',
    name: 'articleDetails',
    component: () => import('@/views/articledetails')
  }, {
    path: '/articleList',
    name: 'articleList',
    component: () => import('@/views/articleList')
  },

  //成功故事
  {
    path: '/directStore/home/story',
    component: () => import("@/views/directStore/homePage/story.vue")
  },
  //服务协议
  {
    path: '/m/portal/register/prDeal',
    component: () => import("@/views/portal/prDeal.vue")
  },
  {
    path: '/m/portal/register/serverDeal',
    component: () => import("@/views/portal/serverDeal.vue")
  },
  // {
  //   path: '/city/:cityName', // 动态路径参数
  //   name: 'city',
  //   component: CityComponent
  // },
  {
    path: '*',
    component: notfound
  }
]


//---------
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})
export default router
