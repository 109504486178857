<template>
  <div></div>
</template>

<script>
export default {
  created() {
    // this.$router.push({
    //   path: "/n/myyezai",
    // });
    this.$router.go(-1)
  },
};
</script>

<style lang="less" scoped>
</style>