import Vue from 'vue'
import Vuex from 'vuex'
//当前用户信息
// import local from "@/api/common/local.js";
// import { infocur } from "@/api/login/login.js";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index: 4,
    directIndex: 4,
    userInfo: null,
    infoId: 0,
    meessCont:0,
    MkeessCont:0,
  },
  mutations: {
    // async increment(state) {
    //   // 获取当前token
    //   let Data = local.get("access_token");
    //   const { code, data } = await infocur(Data);
    //   if (code == 0) {
    //     state.userInfo = data
    //   }
    // }
    //-----------
    // UserData(state, payload) {
    //   state.userInfo = payload;
    // }
    mess(state,p){
      state.meessCont = p
    },
    messLL(state,p){
      state.meessCont = p.number
    },
    Mkeess(state,p){
      state.MkeessCont = p.number
    }
  },
  actions: {
    // increment(context) {
    //   context.commit('increment')
    // }
    ////--------
    // getVillageList(context) {
    //   // 获取当前token
    //   let Data = local.get("access_token");
    //   const { code, data } = infocur(Data);
    //   if (code == 0) {
    //     context.commit('UserData', data)
    //   }
    // }
  },
  getters: {
    // getVillageList(state) {
    //   return state.userInfo
    // }
  },
  modules: {
  }
})
