<template>
  <div></div>
</template>

<script>
import local from "@/api/common/local.js";
export default {
  created() {
    // this.$router.push("/home");
    if (local.get("access_token")) {
      this.$router.push("/n/myyezai");
    } else {
      this.$router.push("/home/");
    }
  },
};
</script>

<style lang="less" scoped>
</style>